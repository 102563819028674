@import url('../fonts/Source+Sans+Pro:400,600,300italic.css');
@font-face {
  font-family: 'cantata_oneregular';
  src: url('../fonts/cantataone-regular.eot');
  src: url('../fonts/cantataone-regular.eot') format('embedded-opentype'), url('../fonts/cantataone-regular.woff') format('woff'), url('../fonts/cantataone-regular.ttf') format('truetype'), url('../fonts/cantataone-regular.svg#cantata_oneregular') format('svg');
  font-weight: normal;
  font-style: normal;
}
.hcard {
  height: 1px;
  overflow: hidden;
  position: absolute;
  top: -100%;
  width: 1px;
}
.hideme {
  display: none!important;
}
.invisible {
  visibility: hidden;
}
#javascript {
  background: #ff0000;
  color: #fff;
  font-size: 15px;
  padding: 30px;
  text-align: center;
}
html {
  font-size: 100%;
  -moz-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}
body {
  margin: 0;
  text-rendering: optimizeLegibility;
}
body.mobile,
body.responsive {
  word-wrap: break-word;
}
html,
button,
input,
select,
textarea {
  font-family: inherit;
}
a {
  cursor: pointer;
}
a:active,
a:focus,
a:hover,
a[tabindex="-1"] {
  outline: 0;
}
address,
em {
  font-style: normal;
}
strong {
  font-weight: normal;
}
u {
  text-decoration: none;
}
sub,
sup {
  font-size: 12px;
  position: relative;
  vertical-align: baseline;
}
sub {
  bottom: -5px;
}
sup {
  top: -8px;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: normal;
  margin: 0;
}
p,
blockquote {
  margin: 0;
}
small {
  font-size: 12px;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
  margin: 0;
}
caption,
th {
  text-align: left;
}
td,
th {
  vertical-align: top;
}
ol {
  list-style: decimal;
}
ul {
  list-style: disc;
}
dd,
dl,
dt,
li,
ol,
ul {
  margin: 0;
  padding: 0;
}
form {
  margin: 0;
}
fieldset {
  border: 0;
  margin: 0;
  padding: 0;
}
legend {
  border: 0;
  padding: 0;
  white-space: normal;
  *margin-left: -7px;
}
label {
  vertical-align: baseline;
  *vertical-align: middle;
}
button,
input[type=date],
input[type=file],
input[type=text],
input[type=time],
select,
textarea {
  font-size: 100%;
  margin: 0;
  vertical-align: baseline;
  *vertical-align: middle;
}
button:focus,
input[type=date]:focus,
input[type=file]:focus,
input[type=text]:focus,
input[type=time]:focus,
select:focus,
textarea:focus {
  outline: 0;
}
button {
  cursor: pointer;
  width: auto;
  -webkit-appearance: none;
  *overflow: visible;
}
button:active {
  outline: 0;
}
button::-moz-focus-inner {
  border: 0;
  padding: 0;
}
input[type=checkbox],
input[type=radio] {
  padding: 0;
  vertical-align: middle;
  *height: 13px;
  *width: 13px;
}
optgroup {
  font-style: normal;
  font-weight: normal;
}
optgroup::-moz-focus-inner {
  border: 0;
  padding: 0;
}
textarea {
  overflow: auto;
  resize: none;
}
img {
  border: 0;
  height: auto;
  max-height: 100%;
  max-width: 100%;
  width: auto;
  -ms-interpolation-mode: bicubic;
}
.img_container {
  overflow: hidden;
}
.img_container img,
.img_container svg {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}
.img_resize {
  height: 0;
  position: relative;
  width: 100%;
}
svg {
  height: 100%;
  -webkit-transform: scale(1);
          transform: scale(1);
  width: 100%;
}
svg a:focus {
  outline: 0;
}
html {
  line-height: 24px;
  font-size: 15px;
  font-family: 'Source Sans Pro', Arial, Helvetica, sans-serif;
}
.no-fontsmoothing {
  font-family: Arial, Helvetica, sans-serif;
}
#sitetitle {
  font-size: 21px;
  line-height: 24px;
  margin-bottom: 24px;
  padding-top: 0;
}
h1,
h2,
h3 {
  padding-top: 6px;
  margin-bottom: 18px;
}
h1,
h2 {
  font-size: 17px;
}
h3 {
  font-size: 15px;
}
h4,
h5,
h6 {
  font-size: 15px;
}
p {
  margin-bottom: 24px;
}
blockquote {
  margin-bottom: 24px;
}
blockquote p {
  font-style: italic;
  margin-bottom: 0;
}
blockquote p:before {
  content: '\201E';
}
blockquote p:after {
  content: '\201C';
}
blockquote small:before {
  content: '\2013\A0';
}
table {
  border-bottom-style: solid;
  border-bottom-width: 1px;
  margin-bottom: 23px;
}
th,
td {
  border-top-style: solid;
  border-top-width: 1px;
  padding-bottom: 12px;
  padding-top: 11px;
}
dd,
dl,
ol,
ul {
  margin-bottom: 24px;
}
li > ul,
li > ol {
  margin-bottom: 0;
}
.hideme_print,
.cms_live_management {
  display: none;
}
.showme_print {
  display: block !important;
}
* {
  background: transparent !important;
  color: black !important;
  text-shadow: none !important;
}
@page {
  margin: 0.5cm;
}
a {
  color: #35877f !important;
  text-decoration: underline;
}
a[href^="tel:"] {
  color: #676d6e !important;
  text-decoration: none;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  orphans: 3;
  widows: 3;
}
h1,
h2,
h3 {
  color: #35877f !important;
  font-weight: bold !important;
}
h1 {
  font-size: 23px !important;
  margin-bottom: 10px !important;
}
h2 {
  font-size: 20px !important;
}
h3 {
  font-size: 17px !important;
}
h2,
h3 {
  margin-bottom: 0 !important;
}
.overview h2 a {
  color: #676d6e !important;
  font-size: 17px !important;
  text-decoration: none !important;
}
.onlinetool h3,
.links h3 {
  color: #676d6e !important;
}
p {
  margin-bottom: 10px;
  orphans: 3;
  widows: 3;
}
.infolists_v1 #content #sitetitle + p.description {
  display: none;
}
table {
  width: 100%;
}
thead {
  display: table-header-group;
}
tr {
  display: table-row !important;
  page-break-inside: avoid;
}
ul,
ol {
  padding-left: 24px;
}
.map {
  height: 600px;
  margin-bottom: 24px;
  width: 99%;
}
.direction img {
  display: none;
}
#logo {
  margin: 48px 0;
}
#logo img {
  border: 1px solid transparent;
}
#menu {
  display: none;
}
.startpage ul {
  list-style: none;
  padding-left: 0;
}
.startpage .startpage_news h2 {
  font-size: 15px !important;
  font-weight: normal !important;
}
